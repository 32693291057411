import React from 'react';
import iconHanley from './hanley.jpg';
import iconMmcb from './mmcb.png';
import iconOsc from './osc.png';
import addland from './addland.png';
import css from './CompaniesLogs.module.css';
import { sendAnalyticsEvent } from '../../../../util/analytics';

const CompaniesLogs = () => {
  const logos = [
    {
      icon: iconHanley,
      link: 'https://www.thehanley.co.uk/',
    },
    {
      icon: addland,
      link: 'https://addland.com/',
    },
    {
      icon: iconOsc,
      link: 'https://www.oscadvice.co.uk/',
    },
    {
      icon: iconMmcb,
      link: 'https://www.mmcbuild.co.uk/',
    },
  ];
  return (
    <div className={css.wrapper} id={'our_partners'}>
      <p className={css.title}>Our Partners</p>
      <div className={css.logosWrapper}>
        {' '}
        {logos.map(i => (
          <a
            onClick={() => {
              sendAnalyticsEvent('link_click', i.link);
              if (typeof window !== 'undefined') window.open(i.link, '_blank');
            }}
            target="_blank"
            className={css.imageWrapper}
          >
            <img src={i.icon} className={css.logo} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default CompaniesLogs;
