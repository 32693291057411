import React from 'react';
import progress2 from './progress2.jpg';
import progress2Mobile from './progress2Mobile.jpg';

import css from './Step2Section.module.css';
import AddlandForms from './AddlandForms';

const Step2Section = () => {
  return (
    <div className={css.wrapper}>
      <img src={progress2} className={css.progress} />
      <img src={progress2Mobile} className={css.progressMobile} />
      <h3 className={css.title}>Step 2 - Find & Research Your Plot</h3>
      <AddlandForms />
    </div>
  );
};

export default Step2Section;
