import React from 'react';
import progress3 from './progress3.jpg';
import progress3Mobile from './progress3Mobile.jpg';
import css from './Step3Section.module.css';
import { Button } from '../../../../components';
import { useHistory } from 'react-router-dom';
import newsletter from '../../../CustomCommunityPage/newsletter1.pdf';

const Step3Section = ({ isLoggedIn }) => {
  const history = useHistory();
  return (
    <div className={css.wrapper}>
      <img src={progress3} className={css.progress} />
      <img src={progress3Mobile} className={css.progressMobile} />

      <h3 className={css.title}>Step 3 - Connect Architects and Offsite Suppliers</h3>
      <p className={css.subtitle}>
        Sign up with The Modern Builder for free, submit your project information and receive a free
        cost estimate on the structure of your build based on offsite construction methods.
      </p>
      <div className={css.buttonsWrapper}>
        <Button
          type="button"
          onClick={() =>
            history.push('/self-build-blogs/how-much-does-it-cost-to-self-build-a-house-in-2024')
          }
          className={css.ctaButton}
        >
          Try Our Cost Calculator
        </Button>
        {!isLoggedIn && (
          <Button type="button" onClick={() => history.push('/signup')} className={css.ctaButton}>
            Get Started
          </Button>
        )}
        <Button
          type="button"
          onClick={() => typeof window !== 'undefined' && window.open(newsletter, '_blank')}
          className={css.ctaButton}
        >
          Latest Newsletter
        </Button>
      </div>
    </div>
  );
};

export default Step3Section;
