import React from 'react';
import progress1 from './progress1.jpg';
import progress1Mobile from './progress1Mobile.jpg';
import fakeWorking from './fakeWorking.jpg';
import minHouse from './minHouse2.jpg';
import modelHouse from './modelHouse.jpg';
import css from './Step1Section.module.css';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components';
import { sendAnalyticsEvent } from '../../../../util/analytics';

const Card = ({ image, title, description }) => (
  <div className={css.cardWrapper}>
    <img src={image} className={css.cardImage} />
    <p className={css.cardTitle}>{title}</p>
    <p className={css.cardDescription}>{description}</p>
  </div>
);

const Step1Section = () => {
  const history = useHistory();
  const cardsData = [
    {
      image: fakeWorking,
      title: 'Self Build Lending Guide',
      description:
        'The guide is here to introduce you to how we work with you to make your dream home a reality.',
    },
    {
      image: minHouse,
      title: 'Self Build Costs',
      description:
        'Determining the cost of building a house can vary widely based on individual circumstances.',
    },
    {
      image: modelHouse,
      title: 'Self Build Incentives',
      description:
        'Are you dreaming of building your home? There are Stamp duty and VAT benefits. Find out more.',
    },
  ];

  return (
    <div className={css.wrapper}>
      <img src={progress1} className={css.progress} />
      <img src={progress1Mobile} className={css.progressMobile} />

      <h3 className={css.title}>Step 1 - Affordability Check</h3>
      <div className={css.cardsWrapper}>
        {cardsData.map(card => (
          <Card {...card} />
        ))}
      </div>

      <Button
        className={css.ctaButton}
        type="button"
        onClick={() => {
          sendAnalyticsEvent(
            'button_click',
            'https://share-eu1.hsforms.com/1EM5-nsmKTW-r_rWp5laEOA2b88ya'
          );
          if (typeof window !== 'undefined') {
            window.open('https://share-eu1.hsforms.com/1EM5-nsmKTW-r_rWp5laEOA2b88ya', '_blank');
          }
        }}
      >
        Book a Call with The Hanley BS
      </Button>
    </div>
  );
};

export default Step1Section;
