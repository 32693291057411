import React from 'react';
import homePic from './homePic.png';
import homePicMobile from './homePicMobile.png';
import checkIcon from './check.png';
import css from './BannerSection2.module.css';
import { Button } from '../../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BannerSection2 = () => {
  const history = useHistory();
  return (
    <div className={css.wrapper}>
      <div className={css.heroLeft}>
        <h1 className={css.title}>
          <strong className={css.bigText}>Compare. Connect. Build.</strong>
          <br />
          The offsite construction platform.
        </h1>
        <div className={css.buttonsWrapper}>
          <Button className={css.cta} type="button" onClick={() => history.push('/how-it-works')}>
            How It Works
          </Button>

          <Button
            className={css.cta}
            type="button"
            onClick={() =>
              history.push('/self-build-blogs/how-much-does-it-cost-to-self-build-a-house-in-2024')
            }
          >
            Cost Calculator
          </Button>
        </div>

        <div className={css.buttonsWrapper2}>
          <Button className={css.ctaDark} type="button" onClick={() => history.push('/community')}>
            Our Community
          </Button>

          <Button
            className={css.ctaDark}
            type="button"
            onClick={() =>
              history.push(
                '/article/Supporting%20Helle’s%20Journey%20to%20a%20More%20Accessible%20Home'
              )
            }
          >
            Customer Journey
          </Button>
        </div>
        {/* <div className={css.checksSection}>
          <div className={css.checkItem1}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Navigate the self-build process</span>
          </div>
          <div className={css.checkItem2}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Control your costs</span>
          </div>
          <div className={css.checkItem1}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Connect with trusted suppliers</span>
          </div>
          <div className={css.checkItem2}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Manage your project</span>
          </div>
        </div> */}
      </div>
      <img src={homePic} className={css.housePic} />
      <img src={homePicMobile} className={css.homePicMobile} />
    </div>
  );
};

export default BannerSection2;
