import React from 'react';
import css from './LogosSection.module.css';
import CompaniesLogs from '../CompaniesLogs/CompaniesLogs';

const LogosSection = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.leftWrapper}>
        <h3 className={css.title}>Custom Build Developers</h3>

        <p className={css.subtitle}>
          List your self build plots <span className={css.redText}>free of charge</span> today on
          our marketplace.{' '}
        </p>

        <a
          className={css.ctaButton}
          href="https://calendly.com/themodernbuilder/discovery"
          target="_blank"
        >
          Book a call with us
        </a>
      </div>
      <div className={css.divider}></div>
      <div className={css.rightWrapper}>
        <CompaniesLogs />
      </div>
    </div>
  );
};

export default LogosSection;
