import React, { useEffect } from 'react';
import css from './AddlandForms.module.css';
import { addlandScript_find, addlandScript_research } from './utils';

const AddlandForms = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const scriptFind = document.createElement('script');
      scriptFind.type = 'text/javascript';
      scriptFind.text = addlandScript_find;
      document.body.appendChild(scriptFind);

      const scriptResearch = document.createElement('script');
      scriptResearch.type = 'text/javascript';
      scriptResearch.text = addlandScript_research;
      document.body.appendChild(scriptResearch);

      return () => {
        document.body.removeChild(scriptFind);
        document.body.removeChild(scriptResearch);
      };
    }
  }, []);

  return (
    <div className={css.formsWrapper}>
      <div className={css.left}>
        <p className={css.underText}>
          Whether you're planning your dream self-build project or searching for the perfect plot to
          bring your vision to life, finding the right land is a crucial first step.
        </p>
        <div
          id="Addland_searchWidget_find"
          data-brand="true"
          data-referrer="grand-designs"
          data-utmcampaign="search-widget"
          data-utmsource="granddesignsmagazine.com"
          data-utmmedium="referral"
          data-journey="find"
        ></div>
      </div>
      <div className={css.divider}></div>
      <div className={css.right}>
        <p className={css.underText}>
          Whether you're seizing the perfect opportunity or researching options for your next
          project, access all the information you need in one place. From flood zones to planning
          permissions, and everything inbetween, Addland delivers instant insights on any registered
          title.
        </p>

        <p className={css.underText}>
          Get 10% off all Property Reports using <strong>TMB10.</strong>
        </p>
        <div
          id="Addland_searchWidget_research"
          data-brand="true"
          data-referrer="grand-designs"
          data-utmcampaign="search-widget"
          data-utmsource="granddesignsmagazine.com"
          data-utmmedium="referral"
          data-journey="research"
        ></div>
      </div>
    </div>
  );
};

export default AddlandForms;
