import React, { useEffect, useState } from 'react';
import shield from './shield.png';
import newsletter from './newsletter.png';
import customers from './customers.png';
import css from './HeroSection.module.css';
import { useHistory } from 'react-router-dom';

const ListItem = ({ icon, title, description, ctaText, ctaLink, history }) => (
  <div className={css.listItemWrapper}>
    <img src={icon} className={css.itemIcon} />
    <div className={css.itemContent}>
      <p className={css.itemTitle}>{title}</p>
      <p className={css.itemDescription}>{description}</p>
    </div>
    <div className={css.itemButton} onClick={() => history.push(ctaLink)}>
      {ctaText}
    </div>
  </div>
);

const HeroSection = () => {
  const history = useHistory();
  const itemsData = [
    {
      icon: shield,
      title: 'Discover our Trusted Partners',
      description: 'Explore our trusted suppliers dedicated to excellent service.',
      ctaText: 'Discover more',
      ctaLink: '#our_partners',
    },
    {
      icon: customers,
      title: 'See How Our Clients Succeed',
      description: 'Check out our innovative solutions and dedicated support.',
      ctaText: 'Discover more',
      ctaLink: '/community',
    },
    {
      icon: newsletter,
      title: 'Stay Tuned For the Latest Updates',
      description: 'Subscribe to our newsletter for exclusive insights.',
      ctaText: 'Subscribe',
      ctaLink: '/community',
    },
  ];
  return (
    <div className={css.wrapper}>
      <h4 className={css.titleMobile}>Stay Connected & Informed</h4>
      <div className={css.sectionTop}>
        <iframe
          id={'videoIframe'}
          className={css.youtubeIframe}
          src="https://player.vimeo.com/video/964512230?h=61121ffd26&autoplay=1&muted=1"
          title="Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <div className={css.sectionContent}>
          <h4 className={css.title}>Stay Connected & Informed</h4>
          <div className={css.listWrapper}>
            {itemsData.map(item => (
              <ListItem {...item} history={history} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
